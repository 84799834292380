


























import { Component, Vue } from 'vue-property-decorator';
import { dispatchGetNewsletter, dispatchUpdateNewsletter } from '@/store/main/accessors';
import VTextFieldWithValidation from '@/components/VTextFieldWithValidation.vue';
import { ValidationProvider } from 'vee-validate';
import { INewsletter } from '@/interfaces';

@Component({
  components: {
    VTextFieldWithValidation,
  },
})
export default class EditNewsletter extends Vue {
  public isValid = true;
  public newsletter: INewsletter = {} as INewsletter;
  public $refs!: {
    observer: InstanceType<typeof ValidationProvider>;
  };

  public async mounted() {
    this.newsletter = await dispatchGetNewsletter(this.$store, Number(this.$router.currentRoute.params.id));
  }

  public async reset() {
    this.newsletter = await dispatchGetNewsletter(this.$store, Number(this.$router.currentRoute.params.id));
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$refs.observer.validate()) {
      const result = await dispatchUpdateNewsletter(this.$store, this.newsletter);
      if (result) {
        this.$router.push({ name: 'main-newsletter-all' });
      }
    }
  }
}
